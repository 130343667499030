.MediaCard {
  display: block;
  position: relative;
  font-size: 1.5em;
  overflow: hidden; }
  .MediaCard .badge {
    position: absolute;
    background-color: #41d6b9;
    width: 1.3em;
    height: 1.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem; }
    .MediaCard .badge .badge-icon {
      width: 70%;
      fill: white; }
    .MediaCard .badge.audiobook {
      top: 1rem;
      right: -1px;
      width: 2.7rem;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem; }
      .MediaCard .badge.audiobook span {
        width: 1.2rem; }
    .MediaCard .badge.favorite {
      top: 1rem;
      left: 5px;
      border-radius: 50%; }
    .MediaCard .badge.plus {
      height: 1.1em;
      width: 5em;
      position: absolute;
      right: -35px;
      bottom: 10px;
      transform: rotate(-45deg); }
      .MediaCard .badge.plus .plus-wrapper {
        width: 2em; }
      .MediaCard .badge.plus .plus-badge {
        color: white;
        height: 15em;
        width: 200px;
        max-height: auto; }
  @media screen and (min-width: 769px), print {
    .MediaCard {
      margin-bottom: 1.5rem;
      box-shadow: 0 0.25rem 1rem rgba(10, 10, 10, 0.33), 0 0 0 1px rgba(10, 10, 10, 0.2); } }

.MediaCardContainer {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden; }

.MediaCardItem {
  width: 100%;
  margin-right: 1.5rem;
  max-width: 12.5%; }
  .MediaCardItem figure {
    height: 100%; }

.MediaCardTitle {
  width: 9rem; }
  .MediaCardTitle:hover, .MediaCardTitle:focus, .MediaCardTitle:active {
    text-decoration: underline; }

.MediaCardLink:hover, .MediaCardLink:focus, .MediaCardLink:active {
  text-decoration: underline;
  text-decoration-color: #41d6b9; }

.MediaCardLink:focus {
  outline: none; }
