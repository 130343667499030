/**
 * This is an adaptation of the `bulma-badge` extension. Rather than just use theirs
 * it was decided to modify it slightly to be the current form for syntactical
 * reasons and so we could have more control over it in the future if necessary.
 *
 * @todo how should this be imported? Currently it is being imported with the
 * `<Button />` since that is the only place it is used, but that's gross and it
 * could be used in other places as well.
 *
 * @see https://github.com/Wikiki/bulma-badge
 */
.has-badge {
  position: relative;
  white-space: nowrap; }
  .has-badge[data-badge]::after {
    position: absolute;
    left: 100%;
    margin: 0;
    background: #41d6b9;
    background-clip: padding-box;
    border-radius: 1rem;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    color: white;
    content: attr(data-badge);
    display: inline-block; }
  .has-badge:not([data-badge=""])::after {
    padding: 0.3rem 0.5rem;
    text-align: center;
    white-space: nowrap; }
  .has-badge[data-badge=""]::after {
    display: inline-block;
    vertical-align: inherit; }
  .has-badge.has-badge-white[data-badge]::after, .has-badge.has-badge-white:not([data-badge])::after {
    background: white;
    color: #0a0a0a; }
  .has-badge.has-badge-black[data-badge]::after, .has-badge.has-badge-black:not([data-badge])::after {
    background: #0a0a0a;
    color: white; }
  .has-badge.has-badge-light[data-badge]::after, .has-badge.has-badge-light:not([data-badge])::after {
    background: whitesmoke;
    color: #363636; }
  .has-badge.has-badge-dark[data-badge]::after, .has-badge.has-badge-dark:not([data-badge])::after {
    background: #363636;
    color: whitesmoke; }
  .has-badge.has-badge-primary[data-badge]::after, .has-badge.has-badge-primary:not([data-badge])::after {
    background: #00d1b2;
    color: #fff; }
  .has-badge.has-badge-link[data-badge]::after, .has-badge.has-badge-link:not([data-badge])::after {
    background: #3273dc;
    color: #fff; }
  .has-badge.has-badge-info[data-badge]::after, .has-badge.has-badge-info:not([data-badge])::after {
    background: #209cee;
    color: #fff; }
  .has-badge.has-badge-success[data-badge]::after, .has-badge.has-badge-success:not([data-badge])::after {
    background: #23d160;
    color: #fff; }
  .has-badge.has-badge-warning[data-badge]::after, .has-badge.has-badge-warning:not([data-badge])::after {
    background: #ffdd57;
    color: rgba(0, 0, 0, 0.7); }
  .has-badge.has-badge-danger[data-badge]::after, .has-badge.has-badge-danger:not([data-badge])::after {
    background: #ff3860;
    color: #fff; }
  .has-badge.has-badge-plus[data-badge]::after, .has-badge.has-badge-plus:not([data-badge])::after {
    background: #f7a92b;
    color: white; }
  .has-badge[data-badge]::after {
    top: calc(0% - ( 1rem / 2 ));
    left: calc(100% - ( 1rem / 2 ));
    min-height: 1rem;
    min-width: 1rem;
    z-index: 5; }
  .has-badge:not([data-badge=""])::after {
    font-size: 0.75rem;
    line-height: 0.5rem; }
  .has-badge[data-badge=""]::after {
    width: 1rem; }
  .has-badge.has-badge-top-left::after {
    left: calc(0% - ( 1rem / 2 )); }
  .has-badge.has-badge-bottom-right::after {
    top: calc(100% - ( 1rem / 2 )); }
  .has-badge.has-badge-bottom-left::after {
    left: calc(0% - ( 1rem / 2 ));
    top: calc(100% - ( 1rem / 2 )); }
  .has-badge.has-badge-small[data-badge]::after {
    top: calc(0% - ( 0.75rem / 2 ));
    left: calc(100% - ( 0.75rem / 2 ));
    min-height: 0.75rem;
    min-width: 0.75rem;
    z-index: 5; }
  .has-badge.has-badge-small:not([data-badge=""])::after {
    font-size: 0.5625rem;
    line-height: 0.375rem; }
  .has-badge.has-badge-small[data-badge=""]::after {
    width: 0.75rem; }
  .has-badge.has-badge-small.has-badge-top-left::after {
    left: calc(0% - ( 0.75rem / 2 )); }
  .has-badge.has-badge-small.has-badge-bottom-right::after {
    top: calc(100% - ( 0.75rem / 2 )); }
  .has-badge.has-badge-small.has-badge-bottom-left::after {
    left: calc(0% - ( 0.75rem / 2 ));
    top: calc(100% - ( 0.75rem / 2 )); }
  .has-badge.has-badge-medium[data-badge]::after {
    top: calc(0% - ( 1.25rem / 2 ));
    left: calc(100% - ( 1.25rem / 2 ));
    min-height: 1.25rem;
    min-width: 1.25rem;
    z-index: 5; }
  .has-badge.has-badge-medium:not([data-badge=""])::after {
    font-size: 0.9375rem;
    line-height: 0.625rem; }
  .has-badge.has-badge-medium[data-badge=""]::after {
    width: 1.25rem; }
  .has-badge.has-badge-medium.has-badge-top-left::after {
    left: calc(0% - ( 1.25rem / 2 )); }
  .has-badge.has-badge-medium.has-badge-bottom-right::after {
    top: calc(100% - ( 1.25rem / 2 )); }
  .has-badge.has-badge-medium.has-badge-bottom-left::after {
    left: calc(0% - ( 1.25rem / 2 ));
    top: calc(100% - ( 1.25rem / 2 )); }
  .has-badge.has-badge-large[data-badge]::after {
    top: calc(0% - ( 1.5rem / 2 ));
    left: calc(100% - ( 1.5rem / 2 ));
    min-height: 1.5rem;
    min-width: 1.5rem;
    z-index: 5; }
  .has-badge.has-badge-large:not([data-badge=""])::after {
    font-size: 1.125rem;
    line-height: 0.75rem; }
  .has-badge.has-badge-large[data-badge=""]::after {
    width: 1.5rem; }
  .has-badge.has-badge-large.has-badge-top-left::after {
    left: calc(0% - ( 1.5rem / 2 )); }
  .has-badge.has-badge-large.has-badge-bottom-right::after {
    top: calc(100% - ( 1.5rem / 2 )); }
  .has-badge.has-badge-large.has-badge-bottom-left::after {
    left: calc(0% - ( 1.5rem / 2 ));
    top: calc(100% - ( 1.5rem / 2 )); }

/**
 * This is an adaptation of the `tooltip` extension. Rather than just use theirs
 * it was decided to modify it slightly to be the current form for syntactical
 * reasons and so we could have more control over it in the future if necessary.
 *
 * @todo how should this be imported? Currently it is being imported with the
 * `<Button />` since that is the only place it is used, but that's gross and it
 * could be used in other places as well.
 *
 * @see https://github.com/Wikiki/bulma-tooltip
 */
.has-tooltip {
  position: relative; }
  .has-tooltip::before {
    position: absolute;
    content: attr(data-tooltip);
    opacity: 0;
    transition: 100ms ease-in-out;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    transform: translate(0, 50%);
    background: rgba(54, 54, 54, 0.75);
    border-radius: 4px;
    color: white;
    max-width: 24rem;
    pointer-events: none; }
  .has-tooltip.has-tooltip-active::before {
    opacity: 1; }
  @media (hover: hover) {
    .has-tooltip:hover::before, .has-tooltip:focus::before {
      opacity: 1; } }
  .has-tooltip.has-tooltip-left::before {
    top: auto;
    bottom: 50%;
    left: auto;
    right: 100%;
    transform: translate(0, 50%); }
  .has-tooltip.has-tooltip-left.has-tooltip-active::before {
    transform: translate(-0.5rem, 50%); }
  @media (hover: hover) {
    .has-tooltip.has-tooltip-left:hover::before, .has-tooltip.has-tooltip-left:focus::before {
      transform: translate(-0.5rem, 50%); } }
  .has-tooltip.has-tooltip-right::before {
    top: auto;
    bottom: 50%;
    left: 100%;
    right: auto;
    transform: translate(0, 50%); }
  .has-tooltip.has-tooltip-right.has-tooltip-active::before {
    transform: translate(0.5rem, 50%); }
  @media (hover: hover) {
    .has-tooltip.has-tooltip-right:hover::before, .has-tooltip.has-tooltip-right:focus::before {
      transform: translate(0.5rem, 50%); } }
  .has-tooltip.has-tooltip-bottom::before {
    top: 100%;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, 0); }
  .has-tooltip.has-tooltip-bottom.has-tooltip-active::before {
    transform: translate(-50%, 0.5rem); }
  @media (hover: hover) {
    .has-tooltip.has-tooltip-bottom:hover::before, .has-tooltip.has-tooltip-bottom:focus::before {
      transform: translate(-50%, 0.5rem); } }
  .has-tooltip.has-tooltip-top::before {
    top: auto;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0); }
  .has-tooltip.has-tooltip-top.has-tooltip-active::before {
    transform: translate(-50%, -0.5rem); }
  @media (hover: hover) {
    .has-tooltip.has-tooltip-top:hover::before, .has-tooltip.has-tooltip-top:focus::before {
      transform: translate(-50%, -0.5rem); } }
  .has-tooltip.has-tooltip-white::before {
    background: rgba(255, 255, 255, 0.75);
    color: #0a0a0a; }
  .has-tooltip.has-tooltip-black::before {
    background: rgba(10, 10, 10, 0.75);
    color: white; }
  .has-tooltip.has-tooltip-light::before {
    background: rgba(245, 245, 245, 0.75);
    color: #363636; }
  .has-tooltip.has-tooltip-dark::before {
    background: rgba(54, 54, 54, 0.75);
    color: whitesmoke; }
  .has-tooltip.has-tooltip-primary::before {
    background: rgba(0, 209, 178, 0.75);
    color: #fff; }
  .has-tooltip.has-tooltip-link::before {
    background: rgba(50, 115, 220, 0.75);
    color: #fff; }
  .has-tooltip.has-tooltip-info::before {
    background: rgba(32, 156, 238, 0.75);
    color: #fff; }
  .has-tooltip.has-tooltip-success::before {
    background: rgba(35, 209, 96, 0.75);
    color: #fff; }
  .has-tooltip.has-tooltip-warning::before {
    background: rgba(255, 221, 87, 0.75);
    color: rgba(0, 0, 0, 0.7); }
  .has-tooltip.has-tooltip-danger::before {
    background: rgba(255, 56, 96, 0.75);
    color: #fff; }
  .has-tooltip.has-tooltip-plus::before {
    background: rgba(247, 169, 43, 0.75);
    color: white; }

.button.is-circular {
  border-radius: 1000px !important; }

.button.has-labeled-icon {
  height: auto;
  display: inline-flex;
  flex-direction: column; }
  .button.has-labeled-icon > .icon,
  .button.has-labeled-icon > .heading {
    margin: 0 !important; }
