.AnnotationActionMenu {
  position: fixed;
  overflow: hidden;
  width: 300px;
  z-index: 95;
  top: 0;
  left: 0;
  transition: 100ms ease-in-out; }
  .AnnotationActionMenu .DefaultContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; }
  .AnnotationActionMenu .view-content {
    max-height: 20rem;
    overflow-y: auto; }
  .AnnotationActionMenu .HighlightView {
    display: flex;
    flex-direction: column; }
    .AnnotationActionMenu .HighlightView .styles {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly; }
      .AnnotationActionMenu .HighlightView .styles .heading {
        flex: 1 1 100%; }
      .AnnotationActionMenu .HighlightView .styles .style-underlined {
        box-shadow: inset 0 -3px; }
        .AnnotationActionMenu .HighlightView .styles .style-underlined > span {
          color: black; }
      .AnnotationActionMenu .HighlightView .styles .style-block {
        background: currentColor; }
        .AnnotationActionMenu .HighlightView .styles .style-block > span {
          color: black; }
    .AnnotationActionMenu .HighlightView .colors {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .AnnotationActionMenu .HighlightView .colors .heading {
        flex: 1 1 100%; }
