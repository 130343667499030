.BookmarkSidebarContent {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .BookmarkSidebarContent .menu {
    flex: 1; }
  .BookmarkSidebarContent .no-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
