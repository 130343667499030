.title-group {
  max-width: 15rem; }
  .title-group .title,
  .title-group .subtitle {
    display: block; }
  @media screen and (min-width: 769px), print {
    .title-group {
      max-width: 20rem; } }
  @media screen and (min-width: 1088px) {
    .title-group {
      max-width: 30rem; } }
