.LoadingIndicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa; }
  .LoadingIndicator--relative {
    position: relative; }
  .LoadingIndicator > div {
    width: 25%;
    max-width: 4rem;
    max-height: 4rem; }
  .LoadingIndicator .svg-icon {
    fill: #41d6b9 !important; }
    .LoadingIndicator .svg-icon #book1,
    .LoadingIndicator .svg-icon #book2,
    .LoadingIndicator .svg-icon #book3 {
      animation-name: moving-books;
      animation-duration: 600ms;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      fill-opacity: 0.75;
      animation-timing-function: ease-in-out; }
    .LoadingIndicator .svg-icon #book2 {
      animation-delay: 150ms; }
    .LoadingIndicator .svg-icon #book3 {
      animation-name: moving-books-2;
      animation-delay: 300ms; }

@keyframes moving-books {
  from {
    transform: translateY(0%); }
  to {
    transform: translateY(20%); } }

@keyframes moving-books-2 {
  from {
    transform: translate(0%, 0%); }
  to {
    transform: translate(2%, 20%); } }
