.Sidebar .SidebarOverlay {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: 99;
  background-color: black;
  transition: opacity 300ms ease-in-out, width 0ms ease-in-out 300ms, height 0ms ease-in-out 300ms; }

.Sidebar .SidebarContent {
  position: fixed;
  z-index: 100;
  background-color: white;
  transition: 300ms ease-in-out;
  transition-property: left, right, top, bottom;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth; }

.Sidebar.is-open .SidebarOverlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  transition: opacity 300ms ease-in-out, width 0ms, height 0ms; }

.Sidebar.is-open .SidebarContent {
  left: 0; }
  .Sidebar.is-open .SidebarContent.is-right {
    left: auto;
    right: 0; }
