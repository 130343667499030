@media screen and (max-width: 768px) {
  .columns > .column.is-one-eighth-mobile {
    flex: none;
    width: 12.5%; }
  .columns > .column.is-three-eighths-mobile {
    flex: none;
    width: 37.5%; }
  .columns > .column.is-five-eighths-mobile {
    flex: none;
    width: 62.5%; }
  .columns > .column.is-seven-eighths-mobile {
    flex: none;
    width: 87.5%; } }

@media screen and (min-width: 769px), print {
  .columns > .column.is-one-eighth-tablet {
    flex: none;
    width: 12.5%; }
  .columns > .column.is-three-eighths-tablet {
    flex: none;
    width: 37.5%; }
  .columns > .column.is-five-eighths-tablet {
    flex: none;
    width: 62.5%; }
  .columns > .column.is-seven-eighths-tablet {
    flex: none;
    width: 87.5%; } }

@media screen and (max-width: 1087px) {
  .columns > .column.is-one-eighth-touch {
    flex: none;
    width: 12.5%; }
  .columns > .column.is-three-eighths-touch {
    flex: none;
    width: 37.5%; }
  .columns > .column.is-five-eighths-touch {
    flex: none;
    width: 62.5%; }
  .columns > .column.is-seven-eighths-touch {
    flex: none;
    width: 87.5%; } }

@media screen and (min-width: 1088px) {
  .columns > .column.is-one-eighth-desktop {
    flex: none;
    width: 12.5%; }
  .columns > .column.is-three-eighths-desktop {
    flex: none;
    width: 37.5%; }
  .columns > .column.is-five-eighths-desktop {
    flex: none;
    width: 62.5%; }
  .columns > .column.is-seven-eighths-desktop {
    flex: none;
    width: 87.5%; } }

@media screen and (min-width: 1280px) {
  .columns > .column.is-one-eighth-widescreen {
    flex: none;
    width: 12.5%; }
  .columns > .column.is-three-eighths-widescreen {
    flex: none;
    width: 37.5%; }
  .columns > .column.is-five-eighths-widescreen {
    flex: none;
    width: 62.5%; }
  .columns > .column.is-seven-eighths-widescreen {
    flex: none;
    width: 87.5%; } }

@media screen and (min-width: 1472px) {
  .columns > .column.is-one-eighth-fullhd {
    flex: none;
    width: 12.5%; }
  .columns > .column.is-three-eighths-fullhd {
    flex: none;
    width: 37.5%; }
  .columns > .column.is-five-eighths-fullhd {
    flex: none;
    width: 62.5%; }
  .columns > .column.is-seven-eighths-fullhd {
    flex: none;
    width: 87.5%; } }

.columns.is-mobile > .column.is-one-eighth {
  flex: none;
  width: 12.5%; }

.columns.is-mobile > .column.is-three-eighths {
  flex: none;
  width: 37.5%; }

.columns.is-mobile > .column.is-five-eighths {
  flex: none;
  width: 62.5%; }

.columns.is-mobile > .column.is-seven-eighths {
  flex: none;
  width: 87.5%; }
