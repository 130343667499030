.VolumeSlider {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center; }
  .VolumeSlider .rc-slider-track {
    background-color: #41d6b9; }
  .VolumeSlider .volume-icon {
    height: 1.5rem;
    cursor: pointer; }
  .VolumeSlider .slider {
    height: 4rem; }
    .VolumeSlider .slider.dropdown {
      height: 5rem; }
  .VolumeSlider .volume-slide-track .dropdown-content {
    justify-content: center;
    display: flex;
    width: 2rem; }
