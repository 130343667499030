.ListeningPage .description {
  height: 14rem;
  position: relative; }
  .ListeningPage .description::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(0deg, #fafafa, transparent);
    height: 3rem; }
