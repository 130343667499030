.AnnotationFab {
  right: 0; }
  .AnnotationFab .nested-fabs {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    top: calc(-50% - (27px / 2));
    left: -2.5rem;
    max-width: 0;
    max-height: 0;
    transition: max-height 0ms ease-out 500ms, max-width 0ms ease-out 500ms; }
    .AnnotationFab .nested-fabs .button {
      opacity: 0;
      transition: 200ms;
      transform: translateX(1rem);
      pointer-events: none; }
      .AnnotationFab .nested-fabs .button:nth-child(1) {
        transition-delay: 50ms; }
      .AnnotationFab .nested-fabs .button:nth-child(2) {
        transition-delay: 100ms; }
      .AnnotationFab .nested-fabs .button:nth-child(3) {
        transition-delay: 150ms; }
      .AnnotationFab .nested-fabs .button:nth-child(4) {
        transition-delay: 200ms; }
      .AnnotationFab .nested-fabs .button:nth-child(5) {
        transition-delay: 250ms; }
      .AnnotationFab .nested-fabs .button:nth-child(6) {
        transition-delay: 300ms; }
      .AnnotationFab .nested-fabs .button:nth-child(7) {
        transition-delay: 350ms; }
      .AnnotationFab .nested-fabs .button:nth-child(8) {
        transition-delay: 400ms; }
      .AnnotationFab .nested-fabs .button:nth-child(9) {
        transition-delay: 450ms; }
      .AnnotationFab .nested-fabs .button:nth-child(10) {
        transition-delay: 500ms; }
      .AnnotationFab .nested-fabs .button:nth-child(11) {
        transition-delay: 550ms; }
      .AnnotationFab .nested-fabs .button:nth-child(12) {
        transition-delay: 600ms; }
      .AnnotationFab .nested-fabs .button:nth-child(13) {
        transition-delay: 650ms; }
      .AnnotationFab .nested-fabs .button:nth-child(14) {
        transition-delay: 700ms; }
      .AnnotationFab .nested-fabs .button:nth-child(15) {
        transition-delay: 750ms; }
      .AnnotationFab .nested-fabs .button:nth-child(16) {
        transition-delay: 800ms; }
      .AnnotationFab .nested-fabs .button:nth-child(17) {
        transition-delay: 850ms; }
      .AnnotationFab .nested-fabs .button:nth-child(18) {
        transition-delay: 900ms; }
      .AnnotationFab .nested-fabs .button:nth-child(19) {
        transition-delay: 950ms; }
    .AnnotationFab .nested-fabs .button + .button {
      margin-top: 0.25rem; }
  .AnnotationFab:hover .nested-fabs, .AnnotationFab:active .nested-fabs, .AnnotationFab:focus .nested-fabs {
    transition: max-height 0ms ease-out 0ms, max-width 0ms ease-out 0ms;
    max-height: 100rem;
    max-width: 100rem; }
    .AnnotationFab:hover .nested-fabs .button, .AnnotationFab:active .nested-fabs .button, .AnnotationFab:focus .nested-fabs .button {
      opacity: 1;
      transform: translateX(-0.5rem);
      pointer-events: inherit; }
  .AnnotationFab.is-left {
    right: auto;
    left: 0; }
    .AnnotationFab.is-left .nested-fabs {
      left: auto;
      right: -2.5rem; }
      .AnnotationFab.is-left .nested-fabs .button {
        transform: translateX(-1rem); }
    .AnnotationFab.is-left:hover .nested-fabs .button, .AnnotationFab.is-left:active .nested-fabs .button, .AnnotationFab.is-left:focus .nested-fabs .button {
      transform: translateX(0.5rem); }
  @media screen and (min-width: 1280px) {
    .AnnotationFab {
      right: 6rem; }
      .AnnotationFab.is-left {
        right: auto;
        left: 6rem; } }
  @media screen and (min-width: 1472px) {
    .AnnotationFab {
      right: 12rem; }
      .AnnotationFab.is-left {
        right: auto;
        left: 12rem; } }
