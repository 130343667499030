.navbar-burger {
  order: -1;
  margin-left: 0 !important;
  color: inherit !important; }
  .navbar-burger.is-desktop {
    display: block; }
  .navbar-burger.is-right {
    order: unset;
    margin-left: auto; }

.navbar-link {
  height: 100%;
  width: 100%; }

.navbar-item {
  min-width: 3.25rem; }

.navbar .navbar-item .icon:only-child,
.navbar .navbar-link .icon:only-child {
  margin-left: auto;
  margin-right: auto; }

.navbar.is-mobile .container {
  display: flex; }

.navbar.is-mobile .navbar-link {
  padding-right: 0.75rem;
  display: flex;
  align-items: center; }

.navbar.is-mobile .navbar-item {
  display: flex;
  align-items: center; }
  .navbar.is-mobile .navbar-item.has-dropdown {
    padding: 0;
    align-items: stretch; }
    .navbar.is-mobile .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-mobile .navbar-item.has-dropdown.is-active .navbar-link {
      background-color: #fafafa; }

.navbar.is-mobile .navbar-dropdown {
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 2px solid #dbdbdb;
  box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
  display: none;
  font-size: 0.875rem;
  left: 0;
  min-width: 100%;
  position: absolute;
  top: 100%;
  z-index: 20; }
  .navbar.is-mobile .navbar-dropdown .navbar-item {
    padding: 0.375rem 1rem;
    white-space: nowrap; }
  .navbar.is-mobile .navbar-dropdown a.navbar-item {
    padding-right: 3rem; }
    .navbar.is-mobile .navbar-dropdown a.navbar-item:hover {
      background-color: #fafafa;
      color: #0a0a0a; }
    .navbar.is-mobile .navbar-dropdown a.navbar-item.is-active {
      background-color: #fafafa;
      color: #41d6b9; }
  .navbar.is-spaced .navbar.is-mobile .navbar-dropdown, .navbar.is-mobile .navbar-dropdown.is-boxed {
    border-radius: 6px;
    border-top: none;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    display: block;
    opacity: 0;
    pointer-events: none;
    top: calc(100% + (-4px));
    transform: translateY(-5px);
    transition-duration: 86ms;
    transition-property: opacity, transform; }
  .navbar.is-mobile .navbar-dropdown.is-right {
    left: auto;
    right: 0; }

.navbar.is-mobile .navbar-item.has-dropdown-up .navbar-link::after {
  transform: rotate(135deg) translate(0.25em, -0.25em); }

.navbar.is-mobile .navbar-item.has-dropdown-up .navbar-dropdown {
  border-bottom: 2px solid #dbdbdb;
  border-radius: 6px 6px 0 0;
  border-top: none;
  bottom: 100%;
  box-shadow: 0 -8px 8px rgba(10, 10, 10, 0.1);
  top: auto; }

.navbar.is-mobile .navbar-item.is-active .navbar-dropdown,
.navbar.is-mobile .navbar-item.is-hoverable:hover .navbar-dropdown {
  display: block; }
  .navbar.is-spaced .navbar.is-mobile .navbar-item.is-active .navbar-dropdown, .navbar.is-mobile .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar.is-spaced
  .navbar.is-mobile .navbar-item.is-hoverable:hover .navbar-dropdown,
  .navbar.is-mobile .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0); }

.navbar.is-mobile,
.navbar.is-mobile .navbar-menu,
.navbar.is-mobile .navbar-start,
.navbar.is-mobile .navbar-center,
.navbar.is-mobile .navbar-end {
  align-items: stretch;
  display: flex;
  padding: 0;
  box-shadow: 0 0; }

.navbar.is-mobile .navbar-menu {
  flex-grow: 1;
  flex-shrink: 0; }

.navbar.is-mobile .navbar-start {
  justify-content: flex-start;
  margin-right: auto; }

.navbar.is-mobile .navbar-center {
  justify-content: center;
  margin: 0 auto; }

.navbar.is-mobile .navbar-end {
  justify-content: flex-end;
  margin-left: auto; }

.navbar.is-mobile.is-white .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-mobile.is-white .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #f2f2f2;
  color: #0a0a0a; }

.navbar.is-mobile.is-white .navbar-dropdown a.navbar-item.is-active {
  background-color: white;
  color: #0a0a0a; }

.navbar.is-mobile.is-black .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-mobile.is-black .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: black;
  color: white; }

.navbar.is-mobile.is-black .navbar-dropdown a.navbar-item.is-active {
  background-color: #0a0a0a;
  color: white; }

.navbar.is-mobile.is-light .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-mobile.is-light .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #e8e8e8;
  color: #363636; }

.navbar.is-mobile.is-light .navbar-dropdown a.navbar-item.is-active {
  background-color: whitesmoke;
  color: #363636; }

.navbar.is-mobile.is-dark .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-mobile.is-dark .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #292929;
  color: whitesmoke; }

.navbar.is-mobile.is-dark .navbar-dropdown a.navbar-item.is-active {
  background-color: #363636;
  color: whitesmoke; }

.navbar.is-mobile.is-primary .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-mobile.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #00b89c;
  color: #fff; }

.navbar.is-mobile.is-primary .navbar-dropdown a.navbar-item.is-active {
  background-color: #00d1b2;
  color: #fff; }

.navbar.is-mobile.is-link .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-mobile.is-link .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #2366d1;
  color: #fff; }

.navbar.is-mobile.is-link .navbar-dropdown a.navbar-item.is-active {
  background-color: #3273dc;
  color: #fff; }

.navbar.is-mobile.is-info .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-mobile.is-info .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #118fe4;
  color: #fff; }

.navbar.is-mobile.is-info .navbar-dropdown a.navbar-item.is-active {
  background-color: #209cee;
  color: #fff; }

.navbar.is-mobile.is-success .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-mobile.is-success .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #20bc56;
  color: #fff; }

.navbar.is-mobile.is-success .navbar-dropdown a.navbar-item.is-active {
  background-color: #23d160;
  color: #fff; }

.navbar.is-mobile.is-warning .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-mobile.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #ffd83d;
  color: rgba(0, 0, 0, 0.7); }

.navbar.is-mobile.is-warning .navbar-dropdown a.navbar-item.is-active {
  background-color: #ffdd57;
  color: rgba(0, 0, 0, 0.7); }

.navbar.is-mobile.is-danger .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-mobile.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #ff1f4b;
  color: #fff; }

.navbar.is-mobile.is-danger .navbar-dropdown a.navbar-item.is-active {
  background-color: #ff3860;
  color: #fff; }

.navbar.is-mobile.is-plus .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-mobile.is-plus .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #f69f12;
  color: white; }

.navbar.is-mobile.is-plus .navbar-dropdown a.navbar-item.is-active {
  background-color: #f7a92b;
  color: white; }

.navbar-brand img {
  height: 1.75rem; }
